/** @jsx jsx */
import { jsx, Button, Input } from "theme-ui";
import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";
import { Section, Wrapper, RightArrow, Scroll } from "~/components.js";
import Children from "~/motion/Children";
import SlideUpItem from "~/motion/SlideUpItem";
import FadeInItem from "~/motion/FadeInItem";
import Seo from "~/Seo.js";
import Timer from "~/Timer.js";
import "~/fonts/fonts.css";

const styles = {
  heading: {
    fontSize: [42, 48],
    lineHeight: ["44px", "56px"],
    fontFamily: "TommySoft-Black",
    letterSpacing: -1,
    margin: 0,
    padding: 0
  },
  heading2: {
    fontSize: [30, 36],
    lineHeight: ["32px", "40px"],
    fontFamily: "TommySoft-Black",
    letterSpacing: -0.5,
    margin: 0,
    padding: 0
  },
  giveaways: {
    fontSize: [22, 34],
    width: "100%",
    padding: 0,
    paddingLeft: [0, 50],
    fontFamily: "TommySoft-Bold",
    listStyle: "none",
    li: {
      display: "flex",
      alignItems: "center",
      justifyContent: ["center", "flex-start"],
      marginBottom: 15,
      "&:last-child": {
        marginBottom: "none"
      }
    }
  },
  button: {
    background: "red",
    color: "white",
    maxWidth: 390,
    width: "100%",
    fontSize: 20,
    display: "inline-flex",
    fontFamily: "TommySoft-Bold",
    lineHeight: "64px",
    borderRadius: 8,
    paddingLeft: 44,
    position: "relative",
    transition: "all 150ms ease-in-out",
    svg: { right: 30, transition: "all 150ms ease-in-out" },
    "&:hover": {
      transform: "translateY(-3px)",
      svg: {
        right: 20
      }
    }
  }
};

const items = [
  "Live Panels & Discussions",
  "$5000 in free giveaways",
  "Nightly All-Star viewing party",
  "Drinks, Food & Games",
  "SoleSavy Kicks Cam",
  "Live DJ'S"
];

const IndexPage = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    function addScript(src) {
      var s = document.createElement("script");
      s.setAttribute("src", src);
      document.body.appendChild(s);
    }
    addScript("https://solesavy.activehosted.com/f/embed.php?id=63");
  }, []);

  return (
    <Children shouldAnimate={true}>
      <StaticQuery
        query={graphql`
          query SEO {
            site {
              siteMetadata {
                title
                description
                siteUrl
                image
                social {
                  twitter
                  instagram
                }
              }
            }
          }
        `}
        render={data => {
          return <Seo site={data.site.siteMetadata} />;
        }}
      />
      <main sx={{ position: "relative" }}>
        <Section style={{ position: "relative", paddingBottom: [0, 150] }}>
          <div
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              "&:after": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(89.86% 89.86% at 89.48% 0%, rgba(0, 0, 0, 0) 0%, #000000 100%)",
                width: "100%",
                height: "100%"
              }
            }}
          >
            <StaticImage
              src="../images/hero.jpg"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "contain"
              }}
              alt="Hero Image"
            />
          </div>
          <Wrapper style={{ display: "flex", flexWrap: "wrap" }}>
            <div sx={{ width: ["100%", "50%"] }}>
              <SlideUpItem sx={{ display: ["block", "none"] }}>
                <a href="https://solesavy.com/">
                  <StaticImage
                    src="../images/solesavy.png"
                    placeholder="tracedSVG"
                    alt="SoleSavy"
                    sx={{
                      height: 34,
                      width: 76,
                      margin: "0 -5px 15px -5px"
                    }}
                  />
                </a>
              </SlideUpItem>
              <SlideUpItem>
                <div
                  sx={{
                    marginBottom: [10, 30],
                    fontSize: [12, 14],
                    display: ["block", "flex"],
                    maxWidth: [220, "100%"],
                    alignItems: "center"
                  }}
                >
                  Presented by
                  <a
                    href="https://solesavy.com/"
                    sx={{
                      display: ["inline-flex", " none"],
                      color: "white",
                      textDecoration: "underline"
                    }}
                  >
                    SoleSavy
                  </a>
                  <a
                    href="https://solesavy.com/"
                    sx={{ display: ["none", "block"] }}
                  >
                    <StaticImage
                      src="../images/solesavy.png"
                      alt="SoleSavy"
                      placeholder="tracedSVG"
                      sx={{
                        height: 34,
                        width: 76,
                        margin: "0 5px"
                      }}
                    />
                  </a>
                  in partnership with BiG, and Baron Davis
                </div>
              </SlideUpItem>
              <SlideUpItem>
                <a
                  href="https://solesavy.com/"
                  sx={{ display: ["none", "block"] }}
                >
                  <StaticImage
                    src="../images/savyhouse.png"
                    alt="SavyHouse"
                    placeholder="tracedSVG"
                    sx={{ maxWidth: [150, 350] }}
                  />
                </a>
              </SlideUpItem>
              <SlideUpItem>
                <h1
                  sx={{
                    ...styles.heading,
                    marginTop: [20, 30],
                    marginBottom: [20, 30]
                  }}
                >
                  THE BEST <span sx={{ color: "red" }}>ALL-STAR</span> EVENT IN
                  CLEVELAND
                </h1>
              </SlideUpItem>
              <SlideUpItem>
                <a
                  sx={styles.button}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.eventbrite.ca/e/savy-house-by-solesavy-all-star-weekend-tickets-259631022377"
                >
                  GET FREE TICKETS NOW
                  <RightArrow
                    style={{
                      position: "absolute",
                      top: "50%",
                      marginTop: -12
                    }}
                  />
                </a>
              </SlideUpItem>
              <SlideUpItem>
                <p
                  sx={{
                    fontSize: [16, 20],
                    lineHeight: 1.5,
                    maxWidth: 580,
                    fontFamily: "TommySoft-Regular"
                  }}
                >
                  Join the SoleSavy community, BIG, and Baron Davis Enterprises
                  for a free weekend of live panels, games and a nightly viewing
                  party to watch All-Star festivities live on the big screen.
                  Transportation from downtown Cleveland (Tower City) to Agora
                  is provided free regularly all weekend!
                </p>
              </SlideUpItem>
              <div
                sx={{
                  display: "grid",
                  maxWidth: 700,
                  gridTemplateColumns: ["1fr 1fr 1fr"],
                  fontSize: [14, 20],
                  span: {
                    fontSize: [12, 16],
                    color: "red",
                    textTransform: "uppercase"
                  }
                }}
              >
                <SlideUpItem>
                  <span>When</span>
                  <div>
                    February
                    <br />
                    19-20, 2022
                  </div>
                </SlideUpItem>

                <SlideUpItem>
                  <span>Where</span>
                  <div>
                    Agora Ballroom, <br />
                    Cleveland, OH
                  </div>
                </SlideUpItem>
                <SlideUpItem>
                  <span>Time</span>
                  <div>2PM-11PM</div>
                </SlideUpItem>
              </div>
            </div>
            <div
              sx={{
                width: ["100%", "50%"],
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                fontFamily: "TommySoft-Bold",
                alignItems: "center",
                py: [50, 0]
              }}
            ></div>
          </Wrapper>

          <div
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              marginLeft: "-8px",
              display: ["none", "flex"]
            }}
          >
            <SlideUpItem>
              <Scroll className="bounce" style={{ position: "relative" }} />
            </SlideUpItem>
          </div>
        </Section>
        <Section>
          <Wrapper style={{ display: "flex", flexWrap: "wrap" }}>
            <div sx={{ width: ["100%", "50%"], position: "relative" }}>
              <StaticImage src="../images/giveaways.png" alt="Giveaways" />
            </div>
            <div
              sx={{
                width: ["100%", "50%"],
                display: "flex",
                alignItems: "center"
              }}
            >
              <ul sx={styles.giveaways}>
                {items.map(item => {
                  return (
                    <li>
                      <StaticImage
                        src="../images/arrow.png"
                        alt="Arrow"
                        sx={{
                          width: [28, 56],
                          height: [9, 18],
                          marginRight: ["5px", 10]
                        }}
                      />
                      <span>{item}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Wrapper>
        </Section>

        <Section style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Wrapper
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexWrap: "wrap",
              textAlign: "center",
              ul: {
                listStyle: "none",
                fontSize: [24, 32],
                p: 0,
                m: 0,
                li: { mb: 30 }
              },
              span: {
                display: "inline-flex",
                fontFamily: "TommySoft-Black",
                lineHeight: "36px",
                px: 15,
                borderRadius: "18px",
                fontSize: 16,
                mb: "15px",
                background: "#363636"
              }
            }}
          >
            <div sx={{ width: ["100%", "50%"], mb: [100, 0] }}>
              <h1 sx={{ ...styles.heading, fontSize: [30, 48], mb: 30 }}>
                February 19
              </h1>
              <ul>
                <li>
                  <div>
                    <span>12:30PM</span>
                  </div>
                  Doors Open
                </li>
                <li>
                  <div>
                    <span>1:00PM</span>
                  </div>
                  BIG Summit Panels
                </li>
                <li>
                  <div>
                    <span>3:00PM</span>
                  </div>
                  360 Degree Kicks Cam Begins
                </li>
                <li>
                  <div>
                    <span>4:00PM</span>
                  </div>
                  Contests and Games Begin
                </li>
                <li>
                  <div>
                    <span>6:00PM</span>
                  </div>
                  After Party & Live DJ Set
                </li>
                <li>
                  <div>
                    <span>8:00PM</span>
                  </div>
                  All-Star Saturday Viewing Party
                </li>
              </ul>
            </div>

            <div sx={{ width: ["100%", "50%"], mb: [100, 0] }}>
              <h1 sx={{ ...styles.heading, fontSize: [30, 48], mb: 30 }}>
                February 20
              </h1>
              <ul>
                <li>
                  <div>
                    <span>10:30AM</span>
                  </div>
                  Girl Get Your Bag Brunch
                </li>
                <li>
                  <div>
                    <span>2:00 PM</span>
                  </div>
                  Doors Open
                </li>
                <li>
                  <div>
                    <span>2:00PM</span>
                  </div>
                  Contests, Games, Kicks Cam Begins
                </li>
                <li>
                  <div>
                    <span>3:00PM</span>
                  </div>
                  Collin Sexton AMA
                </li>
                <li>
                  <div>
                    <span>5:00PM</span>
                  </div>
                  After Party & Live DJ Set
                </li>
                <li>
                  <div>
                    <span>8:00PM</span>
                  </div>
                  All-Star Game Viewing Party
                </li>
              </ul>
            </div>
          </Wrapper>
        </Section>

        <Section style={{ padding: 0 }}>
          <Wrapper
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              textAlign: "center",
              p: {
                fontSize: [16, 20],
                lineHeight: 1.5,
                margin: 0,
                marginTop: [10, 20]
              },
              a: {
                "&:hover": {
                  opacity: 0.8
                }
              }
            }}
          >
            <div sx={{ width: ["100%", "50%", "33.33%"], mb: [100, 50] }}>
              <h3 sx={styles.heading2}>Adam Silver</h3>
              <p>Speaker, NBA Commissioner</p>
              <StaticImage
                src="../images/adam.png"
                placeholder="none"
                alt="Adam Silver"
                sx={{
                  maxWidth: 200,
                  my: 40,
                  borderRadius: "100%",
                  overflow: "hidden"
                }}
              />
            </div>
            <div sx={{ width: ["100%", "50%", "33.33%"], mb: [100, 50] }}>
              <h3 sx={styles.heading2}>Justin Bibb</h3>
              <p>Speaker, Mayor of Cleveland</p>
              <StaticImage
                src="../images/justin.png"
                placeholder="none"
                alt="Justin Bibb"
                sx={{
                  maxWidth: 200,
                  my: 40,
                  borderRadius: "100%",
                  overflow: "hidden"
                }}
              />
            </div>
            <div sx={{ width: ["100%", "50%", "33.33%"], mb: [100, 50] }}>
              <h3 sx={styles.heading2}>Collin Sexton</h3>
              <p>Special Guest</p>
              <StaticImage
                src="../images/colin.png"
                placeholder="none"
                alt="Colin Sexton"
                sx={{
                  maxWidth: 200,
                  my: 40,
                  borderRadius: "100%",
                  overflow: "hidden"
                }}
              />
            </div>
            <div sx={{ width: ["100%", "50%", "33.33%"], mb: [100, 50] }}>
              <h3 sx={styles.heading2}>E-V</h3>
              <p>Performing Saturday night</p>
              <StaticImage
                src="../images/ev.png"
                placeholder="none"
                alt="E-V"
                sx={{ maxWidth: 200, my: 40 }}
              />
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/ev/"
                  sx={{ marginRight: 20 }}
                >
                  <StaticImage
                    src="../images/instagram.png"
                    placeholder="tracedSVG"
                    alt="Instagram"
                    sx={{
                      maxWidth: 40
                    }}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/djev"
                >
                  <StaticImage
                    src="../images/twitter.png"
                    placeholder="tracedSVG"
                    alt="Twitter"
                    sx={{
                      maxWidth: 40
                    }}
                  />
                </a>
              </div>
            </div>
            <div sx={{ width: ["100%", "50%", "33.33%"], mb: [100, 50] }}>
              <h3 sx={styles.heading2}>Yulissa</h3>
              <p>Performing Sunday night</p>
              <StaticImage
                src="../images/yulissa.png"
                placeholder="none"
                alt="iamyulissa"
                sx={{ maxWidth: 200, my: 40 }}
              />
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/iamyulissa/"
                >
                  <StaticImage
                    src="../images/instagram.png"
                    placeholder="tracedSVG"
                    alt="Instagram"
                    sx={{
                      maxWidth: 40
                    }}
                  />
                </a>
              </div>
            </div>
          </Wrapper>
        </Section>
        <Section
          style={{
            flexWrap: "wrap",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: 0
          }}
        >
          <h5
            sx={{ width: "100%", fontSize: 16, fontFamily: "TommySoft-Bold" }}
          >
            PARTNERS
          </h5>
          <Wrapper
            style={{
              display: "flex",
              px: 50,
              width: "100%",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: ["center", "center", "space-between"]
            }}
          >
            <StaticImage
              src="../images/strip/hennessy.png"
              placeholder="none"
              alt="Hennessy"
              sx={{ height: 50, width: 89, m: 20 }}
            />
            <StaticImage
              src="../images/strip/history.png"
              placeholder="none"
              alt="History Of The Game"
              sx={{ height: 80, width: 80, m: 20 }}
            />
            <StaticImage
              src="../images/strip/solesavy_strip.png"
              placeholder="none"
              layout="constrained"
              alt="SoleSavy"
              sx={{ height: 50, width: 145, m: 20 }}
            />
            <StaticImage
              src="../images/strip/slic.png"
              placeholder="none"
              layout="constrained"
              alt="SLIC"
              sx={{ height: 50, width: 107, m: 20 }}
            />
            <StaticImage
              src="../images/strip/twitch.png"
              placeholder="none"
              alt="Twitch"
              sx={{ height: 50, width: 81, m: 20 }}
            />
          </Wrapper>
        </Section>
        <Section
          style={{
            position: "relative",
            paddingTop: [300],
            paddingBottom: [150, 300]
          }}
        >
          <div
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
          >
            <StaticImage
              src="../images/transportation.png"
              alt="Transportation"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: ["40%", "100%"]
              }}
            />
          </div>
          <Wrapper style={{ position: "relative" }}>
            <StaticImage
              src="../images/shuttlebus.png"
              alt="footer"
              sx={{
                maxWidth: 440
              }}
            />
          </Wrapper>
        </Section>
        <Section
          style={{
            minHeight: ["auto", "100vh"],
            paddingTop: [0, 0],
            paddingBottom: [300, 0]
          }}
        >
          <StaticImage
            src="../images/footer.png"
            alt="footer"
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              maxWidth: ["100%", "60%"]
            }}
          />
          <Wrapper
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center"
            }}
          >
            <div sx={{ width: "100%" }}>
              <div
                className="_form_63"
                sx={{
                  opacity: 0,
                  visibility: "hidden",
                  height: 0,
                  overflow: "hidden"
                }}
              ></div>
              {!success ? (
                <>
                  <h2
                    sx={{
                      fontFamily: "TommySoft-Black",
                      fontSize: [38, 48],
                      letterSpacing: [0, -1],
                      margin: 0
                    }}
                  >
                    Learn more about SoleSavy
                  </h2>

                  <form
                    sx={{
                      display: "flex",
                      width: "100%",
                      my: 20,
                      justifyContent: "center"
                    }}
                    onSubmit={e => {
                      e.preventDefault();
                      const element = document.querySelector(
                        "._form_63 input#email"
                      );
                      // console.log("email", element);
                      element.value = email;
                      const submit = document.querySelector("#_form_63_submit");
                      submit.click();
                      setSuccess(true);
                    }}
                  >
                    <Input
                      placeholder="Your email"
                      required
                      type="email"
                      value={email}
                      sx={{
                        background: "white",
                        height: 64,
                        padding: "0 20px",
                        color: "black",
                        maxWidth: 400,
                        fontSize: 22,
                        borderRadius: "8px 0 0 8px",
                        fontFamily: "TommySoft-Regular"
                      }}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Button
                      type="submit"
                      sx={{
                        background: "red",
                        padding: 0,
                        margin: 0,
                        display: "inline-flex",
                        borderRadius: "0px 8px 8px 0px",
                        alignItems: "center",
                        width: 64,
                        height: 64,
                        justifyContent: "center"
                      }}
                    >
                      <RightArrow />
                    </Button>
                  </form>
                  <p>
                    By provide your email your consent to recieve marketing
                    updates from SoleSavy
                  </p>
                </>
              ) : (
                <div sx={{ fontSize: 24 }}>thank you!</div>
              )}
            </div>
          </Wrapper>
        </Section>
        <footer
          sx={{
            position: "absolute",
            zIndex: 1,
            bottom: 0,
            left: 0,
            width: "100%",
            color: "white",
            fontFamily: "TommySoft-Bold",
            fontSize: 16
          }}
        >
          <Wrapper
            style={{
              py: 30,
              display: "flex",
              margin: "0 auto",
              justifyContent: "space-between"
            }}
          >
            <span>
              <a
                href="https://solesavy.com/"
                sx={{
                  color: "white",
                  textDecoration: "underline"
                }}
              >
                SoleSavy
              </a>
              2022.
            </span>
            <div
              sx={{
                a: {
                  transition: "all 150ms ease-in-out",
                  marginLeft: 20,
                  "&:hover": {
                    opacity: 0.8
                  }
                }
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/solesavy"
              >
                <StaticImage
                  src="../images/instagram.png"
                  alt="Instagram"
                  sx={{
                    maxWidth: 24
                  }}
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/solesavy"
              >
                <StaticImage
                  src="../images/twitter.png"
                  alt="Twitter"
                  sx={{
                    maxWidth: 24
                  }}
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://youtube.com/solesavy"
              >
                <StaticImage
                  src="../images/youtube.png"
                  alt="YouTube"
                  sx={{
                    maxWidth: 24
                  }}
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://facebook.com/solesavy"
              >
                <StaticImage
                  src="../images/facebook.png"
                  alt="Facebook"
                  sx={{
                    maxWidth: 24
                  }}
                />
              </a>
            </div>
          </Wrapper>
        </footer>
      </main>
    </Children>
  );
};

export default IndexPage;
