import React from 'react';
import { motion } from 'framer-motion';

export default function Children({
  children,
  shouldAnimate,
  delayChildren = 0.1,
  staggerChildren = 0.1,
  ...props
}) {
  const variants = {
    animated: {
      opacity: 1,
      transition: { staggerChildren, delayChildren },
    },
    initial: {
      opacity: 0,
      transition: { staggerChildren, staggerDirection: -1 },
    },
  };
  return (
    <motion.div
      animate={shouldAnimate ? 'animated' : 'initial'}
      initial="initial"
      variants={variants}
      {...props}
    >
      {children}
    </motion.div>
  );
}
