/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Seo = ({ site }) => {
  const { title, description, siteUrl } = site;
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const seoTitle = `${title}`;
  const meta = [];

  if (metaDescription.length > 100) {
    console.error("Meta description longer than 100");
  }

  return (
    <Helmet
      title={title}
      titleTemplate={defaultTitle ? `%s – ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: "og:image",
          content: `${siteUrl}/social.jpg`
        },
        {
          property: `og:title`,
          content: seoTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.social.twitter || ``
        },
        {
          name: `twitter:title`,
          content: seoTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    ></Helmet>
  );
};

export default Seo;
