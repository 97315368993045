/** @jsx jsx */
import { jsx } from "theme-ui";

export const Section = props => {
  const { children, style } = props;
  return (
    <section
      sx={{
        backgroundColor: "black",
        paddingTop: [100, 150],
        paddingBottom: [100, 150],
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        color: "white",
        p: {
          color: "#9E9E9E"
        },
        ...style
      }}
    >
      {children}
    </section>
  );
};

export const Wrapper = ({ children, style }) => {
  return (
    <div
      sx={{
        width: "90%",
        maxWidth: 1400,
        position: "relative",
        zIndex: 1,
        ...style
      }}
    >
      {children}
    </div>
  );
};

export function RightArrow({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      sx={{ ...style }}
    >
      <path
        fill="#fff"
        d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586l-4.293 4.293z"
      ></path>
    </svg>
  );
}

export function Scroll(props) {
  return (
    <svg
      sx={{ ...props.style }}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="123"
      fill="none"
    >
      <circle cx="8" cy="8" r="7.5" stroke="#F8F5F3"></circle>
      <path stroke="url(#a)" d="M8 16v107"></path>
      <defs>
        <linearGradient
          id="a"
          x1="8.5"
          x2="8.5"
          y1="16"
          y2="115.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F5F3"></stop>
          <stop offset="1" stopColor="#F8F5F3" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Section;
