import React from 'react'
import {motion} from 'framer-motion'

export default function SlideDownItem ({
  children,
  isControlled = true,
  shouldAnimate,
  distance = 40,
  ...props
}) {
  const itemVariants = {
    animated: {
      opacity: 1,
      y: 0,
      transition: {duration: 1, ease: [0.23, 1, 0.32, 1]}
    },
    initial: {
      y: distance,
      opacity: 0,
      transition: {duration: 1, ease: [0.23, 1, 0.32, 1]}
    }
  }
  const extraProps = !isControlled && {
    animate: shouldAnimate ? 'animated' : 'initial',
    initial: 'initial'
  }
  return (
    <motion.div variants={itemVariants} {...extraProps} {...props}>
      {children}
    </motion.div>
  )
}
